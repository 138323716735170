import { useState, useCallback } from 'react';
import Lottie from 'lottie-react';
import FormControlLabel from '../../../../global/Mui/FormControlLabel';
import Checkbox from '../../../../global/Mui/Checkbox';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { RECYCLING_BOX_STICKERS_URL } from '../../../../config/externalURL';
import tbbBoxCheckAnimation from '../../../../animations/tbb-box-check.json';

import './StickersAdvice.scss';

const StickersAdvice = ({
  onSubmit,
  stepTrackerProps,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback((event) => {
    setIsChecked(event.target.checked);
  }, [isChecked]);

  return (
    <div className="StickersAdvice">
      <div className="StickersAdvice__container">
        <div className="StickersAdvice__container--title">
          ENSURE THE BOX MEETS THE FOLLOWING CRITERIA
        </div>

        <div className="StickersAdvice__container--animation">
          <Lottie
            animationData={tbbBoxCheckAnimation}
            loop
            autoplay
          />
        </div>

        <div className="StickersAdvice__container--content">
          <div className="StickersAdvice__container--content--text">
            <ul>
              <li>I&apos;ll be shipping the box that was provided by Trashie.</li>
              <li>
                Such box includes the two hazmat stickers,
                whether they came pre-applied or I added them manually.
              </li>
            </ul>
          </div>
          <div className="StickersAdvice__container--content--actions">
            <FormControlLabel
              label="Yes, the box meets the criteria"
              control={(
                <Checkbox
                  checkedColor="#2649D4 !important"
                  onChange={handleCheckboxChange}
                />
              )}
            />
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onSubmit}
              disabled={!isChecked}
              trackerProps={stepTrackerProps}
            >
              CONTINUE
            </Button>
          </div>
          <div className="StickersAdvice__container--content--advice">
            Stickers lost?
            <a
              className="StickersAdvice__container--content--advice--link"
              href={RECYCLING_BOX_STICKERS_URL}
              target="_blank"
              rel="noreferrer"
            >
              download and print
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickersAdvice;

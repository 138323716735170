import { useCallback, useState, useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import { useFirebaseAuth } from '../../../../../global/FirebaseProvider/FirebaseProvider';
import InfoSection from '../../../../global/InfoSection';
import LoadingBar from '../../../../global/LoadingBar';
import { createCheckoutSession, getGiftCardsBrandsOffer, getRewardsExclusive, getRewardsOffer } from '../../../../utils/service';
import { isProd } from '../../../../../../config/config';
import { TRASHIE_STRIPE_PUBLIC_KEY } from '../../../../config/config';
import IconCircled from '../../../../global/IconCircled';
import { REWARD_STATUSES } from '../../../../config/rewards';
import { getGenericError } from '../../../../utils/errors';
import { formatCurrencyNumber } from '../../../../utils/numbers';
import useTbbPaymentConfig from '../../../../../../hooks/useTbbPaymentConfig';
import { TBB_PARTNER_TAG, TBB_PAYMENT_CONFIG_ITEMS, insertTCIcon } from '../../../../utils/tbbRegistration';
import { FLAGS, getCategoryParam } from '../../../Rewards/utils';

import './Payment.scss';

const stripePromise = loadStripe(
  isProd()
    ? TRASHIE_STRIPE_PUBLIC_KEY.PRODUCTION
    : TRASHIE_STRIPE_PUBLIC_KEY.TEST,
);

const Payment = ({
  code,
  payOnShipFee,
  storeConfig,
}) => {
  const { isSignedIn, isLoading: isLoadingFirebase } = useFirebaseAuth();
  const { isLoading: isLoadingTbbPaymentConfig, tbbPaymentConfig } = useTbbPaymentConfig();

  const [loadingStripe, setLoadingStripe] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [rewardsExclusive, setRewardsExclusive] = useState([]);
  const [giftCards, setGiftCards] = useState([]);
  const [errorStripe, setErrorStripe] = useState(null);

  const fetchClientSecret = useCallback(async () => {
    setLoadingStripe(true);

    const {
      data,
      error: createCheckoutSessionError,
    } = await createCheckoutSession(code);

    if (createCheckoutSessionError || !data.clientSecret) {
      setErrorStripe(createCheckoutSessionError || getGenericError());
      setClientSecret(null);
      setLoadingStripe(false);
      return;
    }

    setClientSecret(data.clientSecret);
    setErrorStripe(null);
    setLoadingStripe(false);
  }, [code]);

  const fetchRewards = useCallback(async () => {
    setLoadingInfo(true);

    const data = await Promise.all([
      getRewardsOffer({
        page: 1,
        pageSize: 3,
        sort: 'sortRank',
        exchangeValueMin: 1,
        status: REWARD_STATUSES.ACTIVE,
      }),
      getRewardsExclusive({
        redemptionCode: code,
        page: 1,
        pageSize: 3,
      }),
      getGiftCardsBrandsOffer({
        pageSize: 3,
        page: 1,
        ...getCategoryParam(FLAGS.IS_HOT),
      }),
    ]);

    const {
      data: rewardsData,
      error: rewardsError,
    } = data[0];

    const {
      data: rewardsExclusiveData,
      error: rewardsExclusiveError,
    } = data[1];

    const {
      data: giftCardsData,
      error: giftCardsError,
    } = data[2];

    setRewards(rewardsError ? [] : rewardsData.rewards);
    setRewardsExclusive(rewardsExclusiveError ? [] : rewardsExclusiveData.rewards);
    setGiftCards(giftCardsError ? [] : giftCardsData.brands);
    setLoadingInfo(false);
  }, []);

  const items = useMemo(
    () => tbbPaymentConfig.items?.map(item => {
      const title = item.titlePayment?.replace(TBB_PARTNER_TAG, storeConfig?.title);

      switch (item.id) {
        case TBB_PAYMENT_CONFIG_ITEMS.recycling:
          return {
            key: item.id,
            title,
            icons: item.content?.map(({ getIcon }) => getIcon()),
          };
        case TBB_PAYMENT_CONFIG_ITEMS.exclusive_rewards:
          return rewardsExclusive.length > 0 ? {
            key: item.id,
            title,
            icons: rewardsExclusive.map(({
              store: {
                id,
                cardBgColor,
                logoUrl,
              },
            }) => (
              <IconCircled
                key={`store-${id}`}
                className="RegisterPayment__container--content-item-icons-icon"
                icon={<img src={logoUrl} alt="logo" />}
                backgroundColor={cardBgColor}
                isWhiteLogo
              />
            )),
          } : null;
        case TBB_PAYMENT_CONFIG_ITEMS.gift_cards:
          return giftCards.length > 0 ? {
            key: item.id,
            title,
            cards: giftCards.map(({ uid, giftCardUrl }) => (
              <img
                key={`brand-${uid}`}
                src={giftCardUrl}
                alt="brand card"
              />
            )),
          } : null;
        case TBB_PAYMENT_CONFIG_ITEMS.unlock_rewards:
          return rewards.length > 0 ? {
            key: item.id,
            title,
            icons: rewards.map(({
              store: {
                id,
                cardBgColor,
                logoUrl,
              },
            }) => (
              <IconCircled
                key={`store-${id}`}
                className="RegisterPayment__container--content-item-icons-icon"
                icon={<img src={logoUrl} alt="logo" />}
                backgroundColor={cardBgColor}
                isWhiteLogo
              />
            )),
          } : null;
        default:
          return null;
      }
    }).filter(Boolean),
    [tbbPaymentConfig, storeConfig, rewards, rewardsExclusive, giftCards],
  );

  const isLoadingInfo = isLoadingFirebase || isLoadingTbbPaymentConfig || loadingInfo;
  const isLoadingPayment = isLoadingFirebase || loadingStripe;

  useEffect(() => {
    if (!isLoadingFirebase && isSignedIn) {
      fetchClientSecret();
      fetchRewards();
    }
  }, [isLoadingFirebase, isSignedIn, fetchClientSecret]);

  return (
    <div className="RegisterPayment">
      <InfoSection titleText="Payment" titleStyles="textTrasformNone" />
      {isLoadingInfo ? (
        <LoadingBar />
      ) : (
        <div className="RegisterPayment__container">
          <div className="RegisterPayment__container--title">
            <span className="RegisterPayment__container--title-text">
              Take Back Service
            </span>
            <div className="RegisterPayment__container--title-price">
              <span className="RegisterPayment__container--title-strike">
                {formatCurrencyNumber(20)}
              </span>
              <span className="RegisterPayment__container--title-value">
                {formatCurrencyNumber(payOnShipFee)}
              </span>
            </div>
          </div>
          {items.map(({
            key,
            title,
            icons,
            cards,
          }) => (
            <div className="RegisterPayment__container--content-item" key={key}>
              <div className={`RegisterPayment__container--content-item-${cards ? 'cards' : 'icons'}`}>
                {cards || icons}
              </div>
              <span>{insertTCIcon(title)}</span>
            </div>
          ))}
        </div>
      )}
      {!isLoadingPayment && (
        errorStripe ? (
          <div className="error">{errorStripe}</div>
        ) : (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout className="RegisterPayment__embeddedCheckout" />
          </EmbeddedCheckoutProvider>
        )
      )}
    </div>
  );
};

export default Payment;

import { useState } from 'react';
import CardSection from '../../Components/CardSection';
import { BUTTON_TYPE } from '../../../../global/Button';
import {
  TbbCardBalanceSection,
  TbbSpendSection,
} from '../../../../global/RegisterTbb/RewardCouponScreen';
import ExploreMoreRewards from '../PostRegistration/ExploreMoreRewards';
import './ShippingAndRewards.scss';
import ReadyToShip from '../PostRegistration/ReadyToShip';
import TbbRegisteredDialog from '../PostRegistration/TbbRegisteredDialog';
import ExclusiveRewardsCarousel from '../PostRegistration/ExclusiveRewardsCarousel';

const ShippingAndReward = ({
  storeConfig,
  onClick,
  shippingMethod,
  creditAmt,
  couponData,
  promoCreditAmount,
  address,
  showDialog,
  recyclingProductType,
  stepTrackerProps,
  redemptionCode,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(true);

  return (
    <div className="ShippingAndReward">
      <div className="ShippingAndReward__container">
        <ReadyToShip
          onClick={onClick}
          shippingMethod={shippingMethod}
          address={address}
          stepTrackerProps={stepTrackerProps}
          storeConfig={storeConfig}
        />
        {(!storeConfig.isTrashie && !storeConfig.registerTbbFlow?.rewardStep?.hideCoupon) && (
          <CardSection title="YOUR Exclusive reward">
            <div className="ShippingAndReward__container--coupon">
              {storeConfig.registerTbbFlow.rewardStep?.couponDescription && (
                <div className="ShippingAndReward__container--coupon--description">
                  {storeConfig.registerTbbFlow.rewardStep.couponDescription}
                </div>
              )}
              <TbbCardBalanceSection
                storeConfig={storeConfig}
                couponData={couponData}
                couponCreditAmt={storeConfig?.registerTbbFlow?.rewardStep?.cashValue}
              />
            </div>
          </CardSection>
        )}
        {
          storeConfig.registerTbbFlow?.rewardStep?.showExclusive &&
          <ExclusiveRewardsCarousel code={redemptionCode} />
        }

        {storeConfig.registerTbbFlow?.rewardStep?.buyAtShop?.enabled && (
          <CardSection
            title={`SHOP${storeConfig.title ? ` WITH ${storeConfig.title.toUpperCase()}` : ''}`}
          >
            <TbbSpendSection storeConfig={storeConfig} />
          </CardSection>
        )}
        {!storeConfig.registerTbbFlow?.rewardStep?.hideRewards && (
          <ExploreMoreRewards
            title="USE YOUR TRASHIECASH™"
            storeConfig={storeConfig}
            buttonText="View All Rewards"
            buttonType={BUTTON_TYPE.QUINARY}
          />
        )}
      </div>
      <TbbRegisteredDialog
        isOpen={isOpenDialog && showDialog}
        onClose={() => setIsOpenDialog(false)}
        {...{ recyclingProductType, storeConfig, creditAmt, promoCreditAmount }}
      />
    </div>
  );
};

export default ShippingAndReward;

import { Fragment, useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import LoadingBar from '../../../../global/LoadingBar';
import useTbbPaymentConfig from '../../../../../../hooks/useTbbPaymentConfig';
import ReadyToShip from './ReadyToShip';
import ExclusiveRewardsCarousel from './ExclusiveRewardsCarousel';
import ExploreMoreRewards from './ExploreMoreRewards';
import TbbRegisteredDialog from './TbbRegisteredDialog';
import { BUTTON_TYPE } from '../../../../global/Button';
import { TBB_PAYMENT_CONFIG_ITEMS } from '../../../../utils/tbbRegistration';
import Slider, { SLIDER_TYPES } from '../../../../global/Slider';
import GiftCardItem, { GIFT_CARD_ITEM_TYPE } from '../../../GiftCards/GiftCardItem';
import { getGiftCardsBrands } from '../../../../utils/service';
import { FLAGS, getCategoryParam } from '../../../Rewards/utils';
import { BRAND_ID_ROUTE_PARAM, ROUTE_GIFT_CARDS_BRAND_ID } from '../../../../config/routes';

import './PostRegistration.scss';

export default function PostRegistration({
  storeConfig,
  onClick,
  shippingMethod,
  address,
  stepTrackerProps,
  redemptionCode,
  showDialog,
  recyclingProductType,
  creditAmt,
  promoCreditAmount,
}) {
  const history = useHistory();
  const { isLoading: isLoadingTbbPaymentConfig, tbbPaymentConfig } = useTbbPaymentConfig();

  const [isOpenDialog, setIsOpenDialog] = useState(true);
  const [giftCards, setGiftCards] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error,
    } = await getGiftCardsBrands({
      pageSize: 6,
      page: 1,
      ...getCategoryParam(FLAGS.IS_HOT),
    });

    setGiftCards(error ? [] : data.brands);
    setIsLoading(false);
  }, []);

  const handleGiftCardOnClick = useCallback(({ uid }) => {
    const relativePath = ROUTE_GIFT_CARDS_BRAND_ID.replace(BRAND_ID_ROUTE_PARAM, uid);

    history.push(relativePath);
  }, [history]);

  const items = useMemo(
    () => tbbPaymentConfig.items
      ?.map((item) => {
        switch (item.id) {
          case TBB_PAYMENT_CONFIG_ITEMS.exclusive_rewards:
            return {
              key: item.id,
              content: <ExclusiveRewardsCarousel code={redemptionCode} />,
            };
          case TBB_PAYMENT_CONFIG_ITEMS.gift_cards:
            return giftCards.length > 0 ? {
              key: item.id,
              content: (
                <div className="PostRegistration__gift-cards">
                  <span className="PostRegistration__gift-cards-title">
                    {item.titlePostRegistration ?? 'Explore gift cards'}
                  </span>
                  <Slider
                    type={SLIDER_TYPES.OVERFLOW}
                    autoplay={false}
                    rewind={false}
                    paginationDots={false}
                    gap={8}
                    fixedPerPage
                    trackPadding={false}
                    slidesPerPage={2}
                    slides={giftCards.map(giftCardItem => ({
                      key: `giftCard-preview-${giftCardItem.uid}`,
                      slide: (
                        <GiftCardItem
                          key={`giftCard-slide-${giftCardItem.uid}`}
                          type={GIFT_CARD_ITEM_TYPE.EXTENDED}
                          giftCardItem={giftCardItem}
                          onClick={handleGiftCardOnClick}
                        />
                      ),
                    }))}
                  />
                </div>
              ),
            } : null;
          case TBB_PAYMENT_CONFIG_ITEMS.unlock_rewards:
            return {
              key: item.id,
              content: (
                <ExploreMoreRewards
                  title="Shop 100’s of additional deals"
                  storeConfig={storeConfig}
                  className="PostRegistration__explore-rewards"
                  buttonText="Shop now!"
                  buttonType={BUTTON_TYPE.QUATERNARY}
                  isRewards={false}
                />
              ),
            };

          default:
            return null;
        }
      })
      .filter(Boolean),
    [tbbPaymentConfig, storeConfig, redemptionCode, giftCards],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="PostRegistration">
      <ReadyToShip
        onClick={onClick}
        shippingMethod={shippingMethod}
        address={address}
        stepTrackerProps={stepTrackerProps}
        storeConfig={storeConfig}
      />
      {isLoadingTbbPaymentConfig || isLoading ? (
        <LoadingBar />
      ) : (
        items.map((item) => <Fragment key={item.key}>{item.content}</Fragment>)
      )}
      <TbbRegisteredDialog
        isOpen={isOpenDialog && showDialog}
        onClose={() => setIsOpenDialog(false)}
        {...{ recyclingProductType, storeConfig, creditAmt, promoCreditAmount }}
      />
    </div>
  );
}
